<template>
  <div class="content">
    <div class="contentmain" v-if="jobDetail && companyDetail">
      <div class="gs-infobox">
        <div class="gs-info" style="width: 100%">
          <div class="gi-left">
            <div class="gl-info font14">
              <div class="p-name font20">
                {{ jobDetail.jobName }}
                <span>
                  {{ (jobDetail.salaryLow / 1000) | numFilter }}
                  K-
                  {{ (jobDetail.salaryHigh / 1000) | numFilter}}
                  K
                </span>
              </div>
              <div class="p-yaoqui">
                {{ jobDetail.city }} <span>|</span>
                {{
                  jobDetail.educationType && jobDetail.educationType.length
                    ? jobDetail.educationType[0].itemText
                    : "学历不限"
                }}
                <span>|</span> {{ jobDetail.expLow }}-{{ jobDetail.expHigh }}年
                <div class="">
                  剩余招聘：<span>{{ jobDetail.jobFree }}</span
                  >人
                </div>
              </div>
              <div class="p-jineng">
                技能语言要求：<span
                  v-for="(item, index) in jobDetail.skillIds"
                  :key="index"
                  v-show="index<8"
                  >{{ item.itemText }}</span>
              </div>
              <div class="p-jineng" v-if="jobDetail.skillIds && jobDetail.skillIds.length>=8" style="overflow: hidden; line-break: anywhere;">
                <span
                    v-for="(item, index) in jobDetail.skillIds"
                    :key="index"
                    v-show="index>7"
                >{{ item.itemText }}</span>
              </div>
            </div>
          </div>
          <el-divider direction="vertical"></el-divider>
          <div class="gi-right">
            <div class="gr-time" v-if="jobDetail.jobType == 1">
              项目周期：{{
                jobDetail.startDate ? jobDetail.startDate.split(" ")[0] : "/"
              }}<span> - </span
              >{{ jobDetail.endDate ? jobDetail.endDate.split(" ")[0] : "/" }}
            </div>
            <div class="daiyu font14">
              <span
                v-show="index < 5"
                v-for="(item, index) in companyDetail.companyWelfare"
                :key="index"
              >
                {{ item.itemText }}
              </span>
              <!-- <span class="diandian">......</span> -->
              <!-- <el-tooltip placement="bottom-end" effect="light">
								<div slot="content" style="padding: 8px 0;">
									<span style="border: 1px solid #00BCFF;margin-left: 20px;border-radius: 50px;color: #00BCFF;padding: 2px 10px;margin-top: 20px;margin-bottom: 30px;">五险一金</span>
									<span style="border: 1px solid #00BCFF;margin-left: 20px;border-radius: 50px;color: #00BCFF;padding: 2px 10px;margin-top: 20px;margin-bottom: 30px;">五险一金</span>
									<span style="border: 1px solid #00BCFF;margin-left: 20px;border-radius: 50px;color: #00BCFF;padding: 2px 10px;margin-top: 20px;margin-bottom: 30px;">五险一金</span>
									<span style="border: 1px solid #00BCFF;margin-left: 20px;border-radius: 50px;color: #00BCFF;padding: 2px 10px;margin-top: 20px;margin-bottom: 30px;">五险一金</span>
									<span style="border: 1px solid #00BCFF;margin-left: 20px;border-radius: 50px;color: #00BCFF;padding: 2px 10px;margin-top: 20px;margin-bottom: 30px;">五险一金</span>
									<br>
									<br>
									<span style="border: 1px solid #00BCFF;margin-left: 20px;border-radius: 50px;color: #00BCFF;padding: 2px 10px;margin-top: 20px;margin-bottom: 30px;">五险一金</span>
									<span style="border: 1px solid #00BCFF;margin-left: 20px;border-radius: 50px;color: #00BCFF;padding: 2px 10px;margin-top: 20px;margin-bottom: 30px;">五险一金</span>
									<span style="border: 1px solid #00BCFF;margin-left: 20px;border-radius: 50px;color: #00BCFF;padding: 2px 10px;margin-top: 20px;margin-bottom: 30px;">五险一金</span>
								</div>
								<span class="diandian">......</span>
							</el-tooltip> -->
              <el-popover
                v-if="
                  companyDetail.companyWelfare &&
                  companyDetail.companyWelfare.length > 5
                "
                placement="bottom-end"
                width="400"
                trigger="click"
              >
                <div style="padding: 8px 0; display: flex; flex-flow: row wrap">
                  <span
                    v-for="(item, index) in companyDetail.companyWelfare"
                    :key="index"
                    v-show="index > 4"
                    style="height: 35px; mergin: 5px; padding: 0 5px"
                  >
                    <span
                      style="
                        border: 1px solid #00bcff;
                        border-radius: 50px;
                        color: #00bcff;
                        height: 20px;
                        padding: 2px 10px;
                        mergin: 0 10px 10px 0;
                      "
                    >
                      {{ item.itemText }}
                    </span>
                  </span>
                </div>

                <div slot="reference" class="tebie" style="padding: 0 10px">
                  ......
                </div>
              </el-popover>
            </div>
          </div>
        </div>
<!--        <div class="caozuo-btn" v-if="!loginStatus">-->
          <!-- 未登录 -->
<!--        </div>-->
        <div class="caozuo-btn" v-if="$route.query.enderType">
          <div class="btn-box" v-if="jobDetail.interviewStatus != null">
            <!-- <el-button class="hand" type="primary" @click="saveInterview(2)" v-if="jobDetail.interviewStatus == 0">拒绝面试</el-button>
						<el-button type="primary" @click="saveInterview(1)" v-if="jobDetail.interviewStatus == 0">接受面试</el-button> -->
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 1"
              >面试已接受</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 2"
              >面试已拒绝</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 3"
              >面试未通过</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 4"
              >面试已通过</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 5"
              >面试已超时</el-button
            >
          </div>
          <div class="btn-box" v-else>
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.sendStatus == 1"
              >简历已投递</el-button
            >
            <el-button
              type="primary"
              v-if="!jobDetail.sendStatus"
              @click="wanshan"
              >投递简历</el-button
            >
          </div>
        </div>
        <div class="caozuo-btn" v-else>
          <div class="btn-box" v-if="jobDetail.interviewStatus != null">
            <!-- <el-button class="hand" type="primary" @click="saveInterview(2)" v-if="jobDetail.interviewStatus == 0">拒绝面试</el-button>
						<el-button type="primary" @click="saveInterview(1)" v-if="jobDetail.interviewStatus == 0">接受面试</el-button> -->
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 1"
              >面试已接受</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 2"
              >面试已拒绝</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 3"
              >面试未通过</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 4"
              >面试已通过</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.interviewStatus == 5"
              >面试已超时</el-button
            >
          </div>
          <div class="btn-box" v-else>
            <el-button
              class="hand"
              type="primary"
              v-if="jobDetail.handStatus == 1"
              >已握手</el-button
            >
            <el-button
              class="hand"
              type="primary"
              v-if="
                !jobDetail.sendStatus &&
                jobDetail.handStatus != 0 &&
                jobDetail.handStatus != 1
              "
              @click="saveHand(0)"
              >再等等</el-button
            >
            <el-button
              type="primary"
              v-if="!jobDetail.handStatus"
              @click="saveHand(1)"
              >接受握手</el-button
            >
          </div>
        </div>
      </div>
      <div class="companybox">
        <div class="synopsis">
          <el-card class="box-card synopsis-mian">
            <div class="s-tit font18">
              职位描述
              <div class="jubao" @click="jubao()">
                <img src="../../assets/images/set/sanjingao.png" />
                举报
              </div>
            </div>
            <div class="contenttext font14">
              <div class="" style="white-space: pre-wrap;">{{ jobDetail.jobDescription }}</div>
              <div class="" v-if="jobDetail.honorCertNames" style="padding-top: 8px">证书要求：{{ jobDetail.honorCertNames }}</div>
              <div class="" v-if="jobDetail.languageLevel && jobDetail.languageLevel.itemText !== undefined
                                  && jobDetail.languageType && jobDetail.languageType.itemText !== undefined">
                语言要求：{{ jobDetail.languageType.itemText }} - {{ jobDetail.languageLevel.itemText }}
                <span class="" v-if="jobDetail.secondLanguageLevel && jobDetail.secondLanguageLevel.itemText !== undefined
                                  && jobDetail.secondLanguageType && jobDetail.secondLanguageType.itemText !== undefined">
                  | {{ jobDetail.secondLanguageType.itemText }} - {{ jobDetail.secondLanguageLevel.itemText }}
                </span>
              </div>
            </div>
            <div class="s-tit font18">工作地点</div>
            <div class="font14" style="color: #666666">
              {{ jobDetail.workPlace }}
            </div>
            <div class="s-tit font18">公司介绍</div>
            <div class="contenttext font14">
              <div class="">{{ companyDetail.companyDescription }}</div>
            </div>
          </el-card>
        </div>
        <div class="other">
          <el-card class="box-card">
            <div class="s-tit font18">公司信息</div>
            <div class="gs-msg font14">
              <div class="gm-name font16">{{ companyDetail.shortName }}</div>
              <div class="">
                <img src="../../assets/images/common/ren-grey.png" />
                <span>{{
                  companyDetail.companySize
                    ? companyDetail.companySize.itemText
                    : ""
                }}</span>
              </div>
              <div class="">
                <img src="../../assets/images/common/gs-grey.png" />
                <span>{{
                  companyDetail.companyType
                    ? companyDetail.companyType.itemText
                    : ""
                }}</span>
              </div>
            </div>
            <div class="lookmore">
              <el-button type="primary" @click="toCompanyDetail"
                >查看公司所有职位</el-button
              >
            </div>
          </el-card>
          <el-card class="like">
            <div class="s-tit font18">相似职位</div>
            <div class="likebox">
              <div
                class="likebox-main"
                v-for="(zhiwei, index6) in searchList"
                :key="index6" @click="hrefJobDetail(zhiwei)"
              >
                <div class="lm-one font16">
                  <div class="">{{ zhiwei.jobName }} · {{ zhiwei.city }}</div>
                  <div class="">
                    {{ (zhiwei.salaryLow / 1000) | numFilter }}
                    K-
                    {{ (zhiwei.salaryHigh / 1000) | numFilter}}
                    K
                  </div>
                </div>
                <div class="lm-two font14">{{ zhiwei.shortName }}</div>
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <!-- 您的简历未完善或视频简历正在审核中 -->
      <!-- <el-dialog class=""  :visible.sync="centerDialogVisible" width="800px" center :show-close="false" :modal-append-to-body="false">
				<div class="registersuccess">
					<span class="closeicon" @click="closebox()">
						<i class="el-icon-error"></i>
					</span>
					<div class="gou_img">
						<img src="../../assets/images/common/position-nodata.png" >
					</div>
					<div class="congratulations font18">
						您的简历未完善或视频简历正在审核中
					</div>
				</div>
			</el-dialog> -->
    </div>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";
export default {
  components: {
    // reportPopup,
    // wanshanPopup
  },
  data() {
    return {
      choosenum: 1,
      centerDialogVisible: false,
      userInfo: {},
      jobDetail: {},
      companyDetail: {},
      searchList: [],
      userJobId: "",
      loginStatus: window.sessionStorage.getItem("LoginStatus") === 'Logged in',
    };
  },
  created() {
    // if (this.getUrl("userName")) {
      // console.log("TODO do login -----", this.getUrl("userName"));
      // console.log("TODO do get query -----", this.$route.query);
    // }

    if(this.$route.query && this.$route.query.userJobId != null){
      this.userJobId = this.$route.query.userJobId;

      this.getUserInfo();
      this.getDetail();
      this.getSearchList();
    } else {
      this.getWantJob();
    }

  },
  mounted() {},
  filters: {
    numFilter(value) {
      let transformVal = Number(value).toFixed(2)
      let realVal = transformVal.substring(0, transformVal.length - 1)

      return Number(realVal)
    }
  },
  methods: {
    getUrl(name) {   //定义GetUrl的方法，作用是对url的字符串进行切割
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.hash.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    hrefJobDetail(item){
      // console.log(item)
      this.$router.push({
        path: "/positiondetail",
        query: { id: item.id, userJobId: this.userJobId },
      });
      window.location.reload();
    },
    getWantJob() {
      //获取求职意向
      // if (this.wantlist.length == 0) {
      //   this.jobList = [];
      //   return;
      // }
      this.$api.getWantJob("get").then((res) => {
        if (res.data && res.data.length > 0) {
          this.userJobId = res.data[0].id;
          this.getUserInfo();
          this.getDetail();
          this.getSearchList();
        }
      });
    },
    getUserInfo() {
      if (this.loginStatus) {
        this.$api.viewResume("get").then((res) => {
          // 获取简历详情
          // "userCv":{},
          // "educationList":[],
          // "projectExpList":[],
          // "honorCertList":[],
          // "userJobList":[],
          // "workExpList":[],
          // "languageList":[],
          // "user":{}
          this.userInfo = res.data;
        });
      }
    },
    getDetail() {
      // console.log("getDetail ------ loginStatus = ", this.loginStatus);
      if (this.loginStatus) {
        this.$api
            .getJobDetail("get", {
              companyJobId: this.$route.query.id,
              userJobId: this.userJobId,
            })
            .then((res) => {
              this.jobDetail = res.data;
              this.getCompanyDetail();
            });
      } else {
        this.$api.getJobInfo("get", {companyJobId: this.$route.query.id}).then((res) => {
          // console.log("getJobInfo ----- 免登录jobInfo获取  res = ", res);
          this.jobDetail = res.data;
          this.getCompanyDetail();
        });
      }
    },
    getCompanyDetail() {
      this.$api
        .getCompanyDetail("get", { companyId: this.jobDetail.companyId })
        .then((res) => {
          this.companyDetail = res.data;
        });
    },
    getSearchList() {
      let config = {
        userJobId: this.userJobId,
        pageNo: 1,
        pageSize: 4,
      };
      this.$api.searchJob("get", config).then((res) => {
        this.searchList = res.data;
      });
    },
    saveHand(type) {
      if (!this.loginStatus) {
        this.$alert("您未登入，无法进行此操作！",
            "提示",
            {
              cancelButtonText: "取消",
              confirmButtonText: "去登入",
              callback: action => {
                this.$router.push("/login");
              }}
        );
        return;
      }
      // console.log("$save hand ----- this.userInfo = ", this.userInfo);
      if(this.userInfo.user.idStatus !== 1) {
        this.$alert("检测到您尚未通过实名认证，请先完善您的个人资料！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if(this.userInfo.userJobList.length === 0) {
        this.$alert("请完善您的简历信息，求职意向不能为空！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.userInfo.workExpList.length === 0) {
        this.$alert("请完善您的简历信息，工作经历不能为空！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.userInfo.educationList.length === 0) {
        this.$alert("请完善您的简历信息，教育经历不能为空！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.userInfo.projectExpList.length === 0) {
        this.$alert("请完善您的简历信息，项目经历不能为空！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.userInfo.user.cvStatus == 1) {
        let config = {
          companyJobId: this.$route.query.id,
          userJobId: this.userJobId,
          status: type,
        };
        this.$api.saveHandle("post", config).then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getDetail();
        });
      } else {
        Bus.$emit("wanshandstuts", true);
      }
    },
    saveInterview(type) {
      this.$api
        .saveInterview("post", {
          interviewId: this.$route.query.id,
          status: type,
        })
        .then(() => {
          this.$message({
            message: "操作成功",
            type: "success",
          });
        });
    },
    closebox() {
      this.centerDialogVisible = false;
    },
    // 举报
    jubao() {
      Bus.$emit("reportdstuts", {
        show: true,
        companyJobId: this.$route.query.id,
      });
    },
    // 完善
    wanshan() {
      if (this.userJobId == "") {
        this.$alert("请先去简历中心设置求职意向", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.userInfo.workExpList.length === 0) {
        this.$alert("请完善您的简历信息，工作经历不能为空！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.userInfo.educationList.length === 0) {
        this.$alert("请完善您的简历信息，教育经历不能为空！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.userInfo.projectExpList.length === 0) {
        this.$alert("请完善您的简历信息，项目经历不能为空！", "提示", {
          confirmButtonText: "确定",
        });
        return;
      }
      if (this.userInfo.user.cvStatus == 1 && this.userInfo.user.idStatus == 1) {
        let config = {
          companyJobId: this.$route.query.id - 0,
          companyUserId: this.jobDetail.companyUserId,
          userJobId: this.userJobId,
          companyId: this.jobDetail.companyId,
        };
        this.$api.sendResume("post", config).then((res) => {
          this.$message({
            message: res.code == 10200 ? "简历投递成功" : res.message,
            type: res.code == 10200 ? "success" : "error",
          });
          if(res.code === 10200) {
            setTimeout( () => {
              this.$api.getJobDetail("get", { companyJobId: this.$route.query.id, userJobId: this.userJobId,})
                  .then((res) => {
                this.jobDetail = res.data;
              });
            }, 100);
          }
        });
      } else {
        Bus.$emit("wanshandstuts", true);
      }
    },
    toCompanyDetail() {
      this.$router.push({
        path: "/qiyedetail",
        query: {
          id: this.jobDetail.companyId,
          userJobId: this.userJobId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}

.content {
  position: relative;
  padding-top: 20px;
  margin-bottom: 180px;
  // transform: translate(8px, 0px);
}
.gs-infobox {
  padding: 20px 30px 30px 30px;
  background-color: #444c5f;
  margin-bottom: 20px;
  .caozuo-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;

    /deep/ .el-button {
      border-radius: 0px;
      width: 94px;
      height: 34px;
      background-color: #00bcff;
      border: none;
      color: #ffffff;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
    }
    .btn-box {
      display: flex;
      align-items: center;
    }
    .hand {
      background-color: #444c5f;
      color: #00bcff;
      border: 1px solid #00bcff;
    }
  }
  .gs-info {
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/.el-divider {
      background: rgb(248 247 252 / 10%);
    }
    /deep/.el-divider--vertical {
      height: 60px;
    }
    .gi-left {
      display: flex;
      align-items: center;
      //flex: 1;
      width: 58%;
      .gl-info {
        color: #dddddd;
        line-height: 2.2;
        .p-name {
          color: #ffffff;
          span {
            color: #ff8400;
            margin-left: 5px;
          }
        }
        .p-yaoqui {
          display: flex;
          align-items: center;
          span {
            margin: 0 10px;
          }
          div {
            margin-left: 30px;
            span {
              color: #00bcff;
            }
          }
        }
        .p-jineng {
          span {
            background: #646d83;
            border-radius: 2px;
            padding: 1px 8px;
            margin-right: 6px;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .gi-right {
      display: flex;
      // align-items: center;
      // justify-content: center;
      flex-direction: column;
      width: 41%;
      //flex: 1;
      .gr-time {
        color: #ffffff;
        text-align: right;
      }
      .daiyu {
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;

        .tebie:hover {
          cursor: pointer;
        }
        span {
          border: 1px solid #00bcff;
          color: #00bcff;
          margin-left: 20px;
          border-radius: 50px;
          color: #ffffff;
          padding: 2px 10px;
          margin-top: 20px;
        }
        .diandian {
          padding: 2px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .daiyuother {
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          .spanses {
            border: 1px solid #00bcff;
            margin-left: 20px;
            border-radius: 50px;
            color: #00bcff;
            padding: 2px 10px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

.s-tit {
  color: #111111;
  margin: 30px 0;
  position: relative;
  .jubao:hover {
    cursor: pointer;
  }
  .jubao {
    position: absolute;
    right: 0;
    top: 10px;
    color: #666666;
    font-size: 14px;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 16px;
      margin-right: 10px;
    }
  }
}
.companybox {
  display: flex;

  .synopsis {
    display: flex;
    flex-direction: column;
    flex: 1;
    .synopsis-mian {
      min-height: 400px;
      /deep/.el-card__body {
        padding: 0 30px 20px 30px;
      }
    }
    .contenttext {
      color: #666666;
      line-height: 30px;
    }
  }
  .other {
    width: 440px;
    // background-color: #0077AA;
    margin-left: 20px;
    /deep/.el-card__body {
      padding: 0 30px 10px 30px;
    }
    .gs-msg {
      color: #111111;
      border-bottom: 1px solid #dddddd;
      div {
        padding-bottom: 30px;
        display: flex;
        align-items: flex-end;
        img {
          width: 22px;
          height: 19px;
          margin-right: 20px;
        }
      }
    }
    .lookmore {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 0 10px 0;
      /deep/ .el-button {
        border-radius: 0px;
        width: 200px;
        height: 34px;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #00bcff;
        border: 1px solid #00bcff;
        background-color: #ffffff;
      }
    }
    .like {
      margin-top: 20px;
      .likebox {
        .likebox-main:hover {
          cursor: pointer;
        }
        .likebox-main {
          margin-bottom: 30px;
          padding-bottom: 20px;
          border-bottom: 1px solid #dddddd;
          .lm-one {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #111111;
            div:last-child {
              color: #ff8400;
            }
          }
          .lm-two {
            color: #000000;
            margin-top: 30px;
          }
        }
      }
    }
  }
}

.registersuccess {
  padding: 84px 0;
  position: relative;
  .gou_img {
    text-align: center;
    img {
      width: 257px;
      height: 216px;
    }
  }
  .congratulations {
    text-align: center;
    color: #111111;
  }
  .closeicon {
    position: absolute;
    top: -40px;
    right: 0;
    font-size: 26px;
    color: #f3f3f3;
  }
}
</style>
